.display-class {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.white-font {
  color:#D9D2D0;
}

.right-align {
  margin-top: 1em;
  margin-right: 5em;
  text-align: right;
}

#navlinks ul{
  list-style: none;
}
#navlinks li{
  display: inline;
}

#navlinks a{
  padding:1em;
}

.info-format {
  text-align: left;
  margin-left: 3em;
}

.info-format h1 {
  text-align: center;
}

.info-format h3 {
  margin-top: 2em;
}

.comments {
  text-align: left;
  margin-top: 3em;
  margin-left: 3em;
  color : white;
}

.comments p {
  margin-left: 2em;
  font-weight:bold;
  color : white;
}

#commentFormat {
  margin-left: 4em;
  font-style:italic;
  font-weight: normal;
}

.extend-field {
  width: 30em;
}

.form-fields {
  width: 7em;
  height: 3em;

}

.save_updates {
  width: 10em;
  height: 3em;
  background-color: #A6958F;
  color: #D9D2D0;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

