img {
  height: 7em;
  width: 7em;
}

.TestIt {
    position: relative;
    display : block;
    padding-top: 1em;
    width: 20em;
    height: 8em;
    margin : .3em;
    background-color:#8C2336;
  }

.ClassDone {
  position: relative;
  display : block;
  padding-top: 1em;
  width: 20em;
  height: 8em;
  margin : .3em;
  background-color: #401021;
}

.details {
  display:inline-block;
  position: relative;
  width: 55%;
  height: 100%;
}

.instructor {
  display: inline-block;
  position: relative;
  width: 40%;
  height: 99%;
}

.ParticipantList {
  position: relative;
  display : block;
  padding-top: .5em;
  width: 15em;
  height: 7em;
  margin : .3em;
  background-color: #D9D2D0;
}
 
.ParticipantDone {
  background-color: #BF2A45;
}

.emphasis-text {
    font-weight: bold;
    font-size: large;
    padding-bottom: .5em;
}  

html{
    background-color: #A6958F;
    /* background-color: #c03636; */
  }
  
  #the-wrap{
    width: 1000px;
    margin: auto;
    
  }
  
  .ball-wrapper{
    float: left;
    margin: 0px 40px;
    
  }
  .ring{
    height:15px;
    width: 5px;
    background-color: #c03636;
    border: 2px solid  #f99d24;
    border-radius: 45%;
    position: relative;
    left: 76.4px;
    top: 12px;
  }
  
  .string{
   
    width: 1.5px;
    position: relative;
    background-color: #f99d24;
    height: 200px;
    left: 80px;
    top: 12.4px;
    margin-top: -20px;
    
    
  }
  
  
  
  .button {
    height: 22px;
    width: 30px;
    background-color: #D93D04;
    position: relative;
    left: 65px;
    top: 7px;
    border-radius: 4px;
  
  
  }
  
  
  .red-ball {
    background-color: #6b2f36;
    border-radius: 50%;
    height: 150px;
    width: 150px;
    position: relative;
    left: 3px;
    
    }
  
  .green-ball {
    background-color: #9cd6ac;
    border-radius: 50%;
    height: 150px;
    width: 150px;
    position: relative;
    left: 3px;
    
    }
  
  .blue-ball{
   background-color: #475479;
    border-radius: 50%;
    height: 150px;
    width: 150px;
    position: relative;
    left: 3px;  
    
  }
  
  .white-ball{
   background-color: #f9f9f9;
    border-radius: 50%;
    height: 150px;
    width: 150px;
    position: relative;
    left: 3px;  
    
  }